<template>
    <div class="page ">
        <full-page ref="fullpage" :options="options">
            <div class="section row  works ">
                <div class="col-md-3 works-left visible-md visible-lg hidden-xs hidden-sm">
                <SidebarWorks @setSubLink="setSubLink" @setLink="setLink" :activeLink="'methodology'" :activeSublink="activeMethodology" ></SidebarWorks>
                </div>
                <div class="col-md-9 works-right fade-in">
                    <div class="methodology-content">
                    <NavigationWorks class="hidden-md hidden-lg visible-xs visible-sm"  :enableNav="false" @setSubLink="setSubLink" @setLink="setLink" :activeLink="'methodology'" :activeSublink="activeMethodology" :arrArray="[0,1,2]" />
                        <h1 class="fade-in" style="margin-top:20px; text-align:center"> {{ translate[language].title }} </h1>
                        <div class="row" v-show="activeMethodology=='' || isMobile ">
                            <div class="methodology-options"> 
                                <div class="methodology-option fade-in animation delay-1" @click="activeMethodology='economic'">
                                    <img src="/images/icon18.png" alt="">
                                    <div class="methodology-text visible-md visible-lg visible-sm visible-xs" v-html="translate[language].methodologies.economic" ></div>
                                </div>
                                <div class="methodology-option fade-in animation delay-2"  @click="activeMethodology='land'">
                                    <img src="/images/icon19.png" alt="">
                                    <div class="methodology-text visible-md visible-lg visible-sm visible-xs" v-html="translate[language].methodologies.land" ></div>
                                </div>
                                <div class="methodology-option fade-in animation delay-3" @click="activeMethodology='planning'">
                                    <img src="/images/icon20.png" alt="">
                                    <div class="methodology-text visible-md visible-lg visible-sm visible-xs" v-html="translate[language].methodologies.planning" > </div>
                                </div>
                                <div class="methodology-option fade-in animation delay-4" @click="activeMethodology='development'">
                                    <img src="/images/icon21.png" alt="">
                                    <div class="methodology-text visible-md visible-lg visible-sm visible-xs" v-html="translate[language].methodologies.development" > </div>
                                </div>
                                <div class="methodology-option fade-in animation delay-5" @click="activeMethodology='delivery'">
                                    <img src="/images/icon22.png" alt="">
                                    <div class="methodology-text visible-md visible-lg visible-sm visible-xs" v-html="translate[language].methodologies.delivery"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row nopadding methodology-context-container" v-if="activeMethodology">
                            <div class="methodology-context">
                                <div class="hidden-md hidden-lg visible-xs visible-md" style="position: absolute; right: 40px; width: 24px; top:0">
                                    <a href="#" @click="activeMethodology=''">
                                        <img src="/images/icon-close.png" style="width:30px;">
                                    </a>
                                </div>
                                <div class="methodology-selected" v-show="activeMethodology=='economic'">
                                    <div class="methodology-title-header fade-in">
                                        <img src="/images/icon18.png" class="methodology-icon visible-md visible-lg visible-sm visible-xs" >
                                        <h2 class="methodology-title" v-html="translate[language].methodologies.economic"  > </h2>
                                    </div>
                                    <ul class="methodology-list">
                                        <li class="fade-in animation " :class="[ 'delay-'+index ]" v-for="index in translate[language].methodologyItems.economic.items.length " :key="index"> {{ translate[language].methodologyItems.economic.items[index - 1] }}</li>
                                    </ul>
                                </div>
                                <div class="methodology-selected" v-show="activeMethodology=='land'">
                                    <div class="methodology-title-header fade-in">
                                        <img src="/images/icon19.png" class="methodology-icon visible-lg visible-md visible-sm visible-xs">
                                        <h2 class="methodology-title" v-html="translate[language].methodologies.land"> </h2>
                                    </div>
                                    <ul class="methodology-list">
                                        <li class="fade-in animation " :class="[ 'delay-'+index ]" v-for="index in translate[language].methodologyItems.land.items.length " :key="index"> {{ translate[language].methodologyItems.land.items[index - 1] }}</li>
                                    </ul>
                                </div>
                                <div class="methodology-selected" v-show="activeMethodology=='planning'">
                                    <div class="methodology-title-header fade-in">
                                        <img src="/images/icon20.png" class="methodology-icon visible-md visible-lg visible-sm visible-xs">
                                        <h2 class="methodology-title"  v-html="translate[language].methodologies.planning"> </h2>
                                    </div>
                                    <ul class="methodology-list">
                                        <li class="fade-in animation" :class="[ 'delay-'+index ]" v-for="index in translate[language].methodologyItems.planning.items.length " :key="index"> {{ translate[language].methodologyItems.planning.items[index - 1] }}</li>
                                    </ul>
                                </div>
                                <div class="methodology-selected" v-show="activeMethodology=='development'">
                                    <div class="methodology-title-header fade-in">
                                        <img src="/images/icon21.png" class="methodology-icon visible-md visible-lg visible-sm visible-xs">
                                        <h2 class="methodology-title" v-html="translate[language].methodologies.development"></h2>
                                    </div>
                                    <ul class="methodology-list">
                                        <li class="fade-in animation" :class="[ 'delay-'+index ]" v-for="index in translate[language].methodologyItems.development.items.length " :key="index"> {{ translate[language].methodologyItems.development.items[index - 1] }}</li>
                                    </ul>
                                </div>
                                <div class="methodology-selected" v-show="activeMethodology=='delivery'">
                                    <div class="methodology-title-header fade-in">
                                        <img src="/images/icon21.png" class="methodology-icon visible-md visible-lg visible-sm visible-xs">
                                        <h2 class="methodology-title" v-html="translate[language].methodologies.delivery"></h2>
                                    </div>
                                    <ul class="methodology-list">
                                        <li class="fade-in animation" :class="[ 'delay-'+index ]" v-for="index in translate[language].methodologyItems.delivery.items.length " :key="index"> {{ translate[language].methodologyItems.delivery.items[index - 1] }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </full-page >
    </div>
</template>
<script>

export default {
    data(){
        return {
            activeMethodology: '',
            isMobile : false,
            options: {
                scrollBar: true,
                responsiveSlides: false,
                easingcss3 : 'cubic-bezier(0.455, 0.310, 0.120, 1.020)',
                licenseKey: '',
                scrollingSpeed : 1000,
                fitToSectionDelay: 2000,
                css3: true,
            },
            language : this.$cookies.get('language') == null ? 'english' : this.$cookies.get('language'),
            translate : {
                spanish: {
                    title: 'METODOLOGÍA',
                    methodologies: {
                        economic : 'Identificación del proyecto',
                        land : 'Adquisición de tierra',
                        planning: 'Planeación',
                        development: 'Desarrollo / Administración',
                        delivery : 'Entregas / Resultados'
                    },
                    methodologyItems : {
                        economic : {
                            items: [
                                'Relaciones y Reputación',
                                'Inteligencia de mercado',
                                'Evaluación al sitio 360',
                                'Estrategia de inversión',
                                'Valoración de negocios',
                            ]
                        },
                        land : {
                            items: [
                                'Manejo legal y correcto del negocio',
                                'Negociación',
                                'Cierre del trato'
                            ]
                        },
                        planning : {
                            items: [
                                'Eficiencia de costos',
                                'Eficiencia de Tiempos',
                                'Regulación, permisos y licencias',
                                'Métodos de innovación en la construcción'
                            ]
                        },
                        development : {
                            items: [
                                'Construcción',
                                'Contratación',
                                'Mercadotecnia',
                                'Ventas',
                                'Sustentabilidad',
                                'Conciencia social'
                            ]
                        },
                        delivery : {
                            items: [
                                'Entregas a Tiempo',
                                'Entrega bajo PRESUPUESTO'
                            ]
                        },
                    }
                },
               
                english: {
                    title: 'METHODOLOGY',
                    methodologies: {
                        economic : 'Economic activation',
                        land : 'Land acquisition',
                        planning: 'Planning',
                        development: 'Development /<br> Management',
                        delivery : 'Delivering /<br> Returns'
                    },
                    methodologyItems : {
                        economic : {
                            items: [
                                'RELATIONSHIPS & REPUTATION',
                                'MARKET INTELLIGENCE',
                                'STRATEGY',
                                'BUSINESS APPRAISAL'
                                ]
                        },
                        land : {
                            items: [
                                'LEGAL BUSINESS DUE DILIGENCE',
                                'NEGOTIATION',
                                'CLOSING',
                                'CLEAR LEGAL LAND TITLES'
                                ]
                        },
                        planning : {
                            items: [
                                'COST EFFICIENCIES/TIME EFFICIENCIES',
                                'REGULATION, PERMITS AND LICENSES',
                                'INNOVATIVE CONSTRUCTION METHODS'
                            ]
                        },
                        development : {
                            items: [
                                'CONSTRUCTION',
                                'HIRING',
                                'MARKETING',
                                'SALES',
                                'MILESTONE COMPLETION',
                                'SUSTAINABILITY',
                                'SOCIAL AWARENESS'
                            ]
                        },
                        delivery : {
                            items: [
                                'ON-TIME DELIVERY',
                                'ON-BUDGET DELIVERY',
                                // 'CONSTRUCTION',
                                // 'CONTRACTING',
                                // 'MARKETING',
                                // 'SALES',
                                // 'MILESTONE COMPLETION',
                                // 'SUSTAINABILITY',
                                // 'SOCIAL AWARENESS'
                            ]
                        },
                    }
                },
            },
        }
    },
    methods: {
        setLink( activeMethodology ){
            this.activeMethodology = activeMethodology
        },
        setSubLink( activeMethodology ){
            this.activeMethodology = activeMethodology
        },
        checkMobile: function() {            
            if(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)){
                this.isMobile = true
            } else {
                this.isMobile = false 
            }
        },
        checkLanguage(){
            let self = this 
            setInterval( function(){
                self.language = ( self.$cookies.get('language') == null ? 'english' : self.$cookies.get('language')  )   // return value      
            }, 1000 )
        },
    },
    created(){
        this.checkMobile()
        this.checkLanguage()
    }
}
</script>

